<template>
  <div>
    <a-card title="收款">
      <a-row :gutter="16">

        <a-col :span="20" :md="8" :xl="6" style="max-width: 256px;">
          <a-input-search v-model="searchForm.search" placeholder="单号" allowClear @search="search" />
        </a-col>

        <a-col :span="11">
          <a-month-picker style="width: 100px;margin-right: 12px" valueFormat="YYYY-MM" :format="'YYYY-MM'"  @change="onChangePicker" placeholder="选择月份" />
          <a-button type="primary" @click="onExport">导出</a-button>

        </a-col>
        <a-col :span="3" style="float: right;">
          <a-button type="primary" icon="plus" @click="handelAdd(form)">新增收款单</a-button>
        </a-col>

      </a-row>

      <a-row style="margin-top: 12px;">
        <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
          @change="tableChange">
          <div slot="allAmount" slot-scope="value, item">

            {{calculate(item.collection_account_items)}}
          </div>

          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" @click="detial(item)">详情</a-button>
              <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
                <a-button type="danger" :disabled="item.is_void">{{ item.is_void ? '已作废' : '作废'}}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
  import { collectionOrdersList, collectionOrdersVoid } from '@/api/finance'
  import {ChangePicker} from "@/utils/excel";

  export default {
    name: 'SaleRecord',
    components: {
    },
    data() {
      return {
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '收款编号',
            dataIndex: 'number',
            sorter: true,
          },
          {
            title: '客户',
            dataIndex: 'client_name',
            width: 170
          },
           {
            title: '经手人',
            dataIndex: 'handler_name',
          },
          {
            title: '录入时间',
            dataIndex: 'create_time',
            width: 170
          },
          {
            title: '发生日期',
            dataIndex: 'handle_time',
            width: 100
          },
          {
            title: '收款银行',
            dataIndex: 'PayBank',
            width: 150

          },
          {
            title: '合计金额',
            // dataIndex: 'collectionAmount',PayBank
            dataIndex: 'allAmount',

            scopedSlots: { customRender: 'allAmount' },

          },
          {
            title: '优惠金额',
            dataIndex: 'discount_amount',
          },
          {
            title: '备注',
            dataIndex: 'remark',
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: 147
          },
        ],
        searchForm: { page: 1, page_size: 16 },
        pagination: { current: 1, total: 0, pageSize: 50 },
        loading: false,
        items: [],
        exportItems:[],
        visible: false,
        targetItem: {},
        form: {},
      };
    },
    computed: {
    },
    methods: {
      onChangePicker(a,b){
        if (a == null) {
          this.searchForm.start_date =  a;
          this.searchForm.end_date = a;
          this.search();
          return;
        }
        const {first_day1,last_day1} = ChangePicker(a,b)
        this.searchForm.start_date =  first_day1;
        this.searchForm.end_date = last_day1;
        this.search();
      },

      onExport() {
        import("../../../utils/xlsx.js").then((excel) => {
          //tHeader 为导出后的表头
          let tHeader = [], filterVal = [], list = []
          tHeader = [ "收款编号", "客户", "经手人", "发生日期", "录入时间","收款银行","合计金额", "优惠金额", "备注", ];
          filterVal =[ "number", "client_name", "handler_name", "handle_time","create_time", "PayBank","allAmount", "discount_amount", "remark", ];
          list = this.exportItems
          list.reverse();
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename:`收款` +this.getCurrentDate(),
            autoWidth: true,
            bookType: "xlsx",
          });
        });

      },
      getCurrentDate() {
        let today = new Date();
        let year = today.getFullYear();
        let month = (today.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要+1，并且补0到两位数
        let day = today.getDate().toString().padStart(2, '0'); // 补0到两位数
        let formattedDate = `${year}${month}${day}`; // 拼接年月日
        return formattedDate;
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
            filterVal.map((j) => {
              if (j === "timestamp") {
                return parseTime(v[j]);
              } else {
                return v[j];
              }
            })
        );
      },
      calculate(dataList){
        let allAmount=0
        dataList.forEach(item=>{
          allAmount=allAmount+parseFloat(item.collection_amount)
        })
        return allAmount

      },
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        collectionOrdersList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;

          this.exportItems=[]
          this.items.forEach(item=>{
            const obj=item
            obj.allAmount=this.calculate(item.collection_account_items)
            obj.discount_amount=parseFloat(item.discount_amount)
            obj.PayBank = item.collection_account_items[0].account_name

            this.exportItems.push(obj)
          })

        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },

      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      handelAdd(item) {
        this.$router.push({ path: '/finance/collection_create' });
      },
      detial(item) {
        this.$router.push({ path: '/finance/collection_detail', query: { id: item.id } });
      },
      voidItem(item) {
        collectionOrdersVoid({ id: item.id }).then(() => {
          this.$message.success('作废成功');
          this.list();
        });
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>
